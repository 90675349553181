/** @format */

import { meetingState } from '@/states/request-meeting';
import { userInfoState } from '@/states/user-info';
import EmptyLayout from '@commons/components/Empty';
import { TypeDataRequestMeeting, TypeStatusDevices } from '@helpers/types';
import '@modules/Meetings/styles/meeting-confirm.scss';
import '@modules/Meetings/styles/meeting-start.scss';
import React from 'react';
import { Form } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import MeetingStart from '../MeetingStart';
import ButtonCheck from './components/ButtonCheck/ButtonCheck';
import { useCheckDevices } from './hooks/useCheckDevices/useCheckDevices';
import { useGetAttendee } from './hooks/useGetAttendee/useGetAttendee';
import { useGetMeeting } from './hooks/useGetMeeting/useGetMeeting';
import { useMeeting } from './hooks/useJoinMeeting/useMeeting';
import { useCookies } from 'react-cookie';

function MeetingConfirm() {
  const [checked, setChecked] = React.useState<string>('');
  const [searchParams] = useSearchParams();
  const [meetingId, setMeetingId] = React.useState<string>('');
  const token = searchParams.get('token');
  const request_id = searchParams.get('request_id');
  const [isJoinMeeting, setIsJoinMeeting] = React.useState<boolean>(false);
  const [id, setId] = React.useState<string>('');
  const [encodedToken, setEncodedToken] = React.useState<string>('');
  const { data, isLoading: loading_meeting, isSuccess } = useGetMeeting(id, encodedToken);
  const { joinMeeting } = useMeeting();
  const [dataRequestMeeting, setDataRequestMeeting] = useRecoilState(meetingState);
  const { data: dataAttendee, isLoading: loading_attendee } = useGetAttendee(id, meetingId, encodedToken);
  const setInfoUserState = useSetRecoilState(userInfoState);
  const [userInfo] = useRecoilState(userInfoState);
  const [isText, setIsText] = React.useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies(['_zwei']);
  const {
    checkAudioOutput,
    checkMic,
    checkCamera,
    checkResolution,
    checkNetworkUDP,
    checkNetworkTCP,
    checkAudioConnect,
    // checkVideoConnect,
  } = useCheckDevices();

  // React.useEffect(() => {
  //   console.log('dataRequestMeeting changed:', dataRequestMeeting);
  // }, [dataRequestMeeting]);

  const handleAllDevicesPassed = () => {
    return (
      // checked === '正常' &&
      checkMic === '正常' &&
      checkCamera === '正常' &&
      checkResolution === '正常' &&
      checkNetworkUDP === '正常' &&
      checkNetworkTCP === '正常' &&
      checkAudioConnect === '正常'
      // && checkVideoConnect === '正常'
    );
  };
  React.useEffect(() => {
    if (data && data?.meeting_info && data.meeting_info.meeting_id) {
      setMeetingId(data.meeting_info.meeting_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (request_id) {
      setId(request_id);
    }
  }, [request_id]);

  React.useEffect(() => {
    if (token) {
      setEncodedToken(token);
    }
  }, [token]);

  // React.useEffect(() => {
  //   if (isJoinMeeting) {
  //     joinMeeting(dataRequestMeeting);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isJoinMeeting]);

  React.useEffect(() => {
    if (data && dataAttendee) {
      const { meeting_info } = data;
      const { media_placement } = meeting_info;
      const { attendee } = dataAttendee;
      const dataMeeting: TypeDataRequestMeeting = {
        attendeeInfo: {
          AttendeeId: attendee?.attendee_info?.attendee_id || '',
          ExternalUserId: attendee?.attendee_info?.attendee_id || '',
          JoinToken: attendee?.attendee_info?.join_token || '',
          BackUrl: attendee?.application_metadata?.back_url || '',
        },
        meetingInfo: {
          MeetingId: meeting_info?.meeting_id,
          MediaRegion: meeting_info?.media_region,
          MediaPlacement: {
            AudioFallbackUrl: media_placement?.audio_fallback_url,
            AudioHostUrl: media_placement?.audio_host_url,
            ScreenDataUrl: media_placement?.screen_data_url,
            ScreenSharingUrl: media_placement?.screen_sharing_url,
            ScreenViewingUrl: media_placement?.screen_viewing_url,
            SignalingUrl: media_placement?.signaling_url,
            TurnControlUrl: media_placement?.turn_control_url,
            EventIngestionUrl: media_placement?.event_ingestion_url,
          },
        },
        zwei_meeting_info: {
          member_id: data?.zwei_meeting_info?.member_id,
          partner_id: data?.zwei_meeting_info?.partner_id,
          matchmaking_at: data?.zwei_meeting_info?.matchmaking_at,
          detail_page_url: data?.zwei_meeting_info?.detail_page_url,
          my_id: data?.zwei_meeting_info?.my_id,
          url_redirect: data?.zwei_meeting_info?.url_redirect,
        },
      };
      setDataRequestMeeting(dataMeeting);
      setInfoUserState({
        user: attendee?.application_metadata?.user,
        partner: attendee?.application_metadata?.partner,
        staff_phone_number: attendee?.application_metadata?.partner?.staff_phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataAttendee]);

  const onStartVideoCall = () => {
    setIsJoinMeeting(true);
    joinMeeting();
  };

  // const onChangeSpeaker = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.id === 'SPEAKER_YES') {
  //     setChecked(TypeStatusDevices.OK);
  //   } else {
  //     setChecked(TypeStatusDevices.NG);
  //   }
  // };

  const onClickCallPhone = () => {
    window.open(`tel:${userInfo?.staff_phone_number}`);
  };

  const onTextCall = () => {
    setIsText(true);
  };

  return (
    <div>
      {!isJoinMeeting ? (
        <EmptyLayout>
          <div id="meetingConfirmPage">
            {loading_meeting || loading_attendee ? (
              <ReactLoading className="loading-video-call" type="spokes" color="#009943" />
            ) : (
              isSuccess && (
                <div>
                  <h3 className="text-center mt-3">オンラインお見合いの事前テストを行います</h3>
                  <div className="button-test">
                    <button onClick={checkAudioOutput}>テスト開始</button>
                  </div>
                  <div className="meeting-confirm-container">
                    <div className="meeting-content">
                      <div className="meeting-container">
                        <p>マイク</p>
                        <ButtonCheck text={checkMic} />
                      </div>
                      <div className="meeting-container">
                        <p>カメラ</p>
                        <ButtonCheck text={checkCamera} />
                      </div>
                      <div className="meeting-container">
                        <p>カメラ解像度</p>
                        <ButtonCheck text={checkResolution} />
                      </div>
                      <div className="meeting-container">
                        <p>通信環境①</p>
                        <ButtonCheck text={checkNetworkTCP} />
                      </div>
                      <div className="meeting-container">
                        <p>通信環境②</p>
                        <ButtonCheck text={checkNetworkUDP} />
                      </div>
                      <div className="meeting-container">
                        <p>音声接続</p>
                        <ButtonCheck text={checkAudioConnect} />
                      </div>
                    </div>
                  </div>
                  <div className="button-test">
                    <button
                      onClick={onStartVideoCall}
                      disabled={!handleAllDevicesPassed()}
                      style={{
                        cursor: handleAllDevicesPassed() ? 'pointer' : 'not-allowed',
                        backgroundColor: handleAllDevicesPassed() ? '#009943' : 'rgba(0, 0, 0, 0.12)',
                        color: handleAllDevicesPassed() ? 'white' : 'rgba(0, 0, 0, 0.26)',
                      }}
                    >
                      待機室に入る
                    </button>
                  </div>
                  {userInfo?.staff_phone_number && (
                    <div onClick={onClickCallPhone} className="button-call">
                      <button>[担当者へ電話する]</button>
                    </div>
                  )}
                  {userInfo?.staff_phone_number && (
                    <div className="button-text-call" onClick={onTextCall}>
                      {isText ? '「' + userInfo?.staff_phone_number + '」' : '[担当者へ電話する]'}
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </EmptyLayout>
      ) : (
        <MeetingStart />
      )}
    </div>
  );
}

export default MeetingConfirm;
