import { meetingState } from '@/states/request-meeting';
import { TypeStatusDevices } from '@helpers/types';
import TestSound from '@utils/TestSound';
import {
  CheckAudioConnectivityFeedback,
  CheckAudioInputFeedback,
  CheckCameraResolutionFeedback,
  CheckNetworkTCPConnectivityFeedback,
  CheckNetworkUDPConnectivityFeedback,
  CheckVideoConnectivityFeedback,
  CheckVideoInputFeedback,
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingReadinessChecker,
  DefaultMeetingSession,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';
import React from 'react';
import { useRecoilState } from 'recoil';
import { getOs } from '@helpers/device';
import { OS } from '@helpers/types';

export function useCheckDevices() {
  const [checkMic, setCheckMic] = React.useState<string>(TypeStatusDevices.WAIT);
  const [checkCamera, setCheckCamera] = React.useState<string>(TypeStatusDevices.WAIT);
  const [checkResolution, setCheckResolution] = React.useState<string>(TypeStatusDevices.WAIT);
  const [checkNetworkUDP, setCheckNetworkUDP] = React.useState<string>(TypeStatusDevices.WAIT);
  const [checkNetworkTCP, setCheckNetworkTCP] = React.useState<string>(TypeStatusDevices.WAIT);
  const [checkAudioConnect, setCheckAudioConnect] = React.useState<string>(TypeStatusDevices.WAIT);
  const [checkVideoConnect, setCheckVideoConnect] = React.useState<string>(TypeStatusDevices.WAIT);

  const logger = new ConsoleLogger('MyLogger');
  const deviceController = new DefaultDeviceController(logger);

  const [stateMeeting] = useRecoilState(meetingState);

  // React.useEffect(() => {
  //   checkDevices();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // prettier-ignore
  const configuration = new MeetingSessionConfiguration(
    {
      "Meeting": {
        "MeetingId": stateMeeting?.meetingInfo?.MeetingId,
        "MediaPlacement": {
            "AudioHostUrl": stateMeeting?.meetingInfo?.MediaPlacement?.AudioHostUrl,
            "AudioFallbackUrl": stateMeeting?.meetingInfo?.MediaPlacement?.AudioFallbackUrl,
            "ScreenDataUrl": stateMeeting?.meetingInfo?.MediaPlacement?.ScreenDataUrl,
            "ScreenSharingUrl": stateMeeting?.meetingInfo?.MediaPlacement?.ScreenSharingUrl,
            "ScreenViewingUrl": stateMeeting?.meetingInfo?.MediaPlacement?.ScreenViewingUrl,
            "SignalingUrl": stateMeeting?.meetingInfo?.MediaPlacement?.SignalingUrl,
            "TurnControlUrl": stateMeeting?.meetingInfo?.MediaPlacement?.TurnControlUrl,
            "EventIngestionUrl": stateMeeting?.meetingInfo?.MediaPlacement?.EventIngestionUrl
        },
        "MediaRegion": stateMeeting?.meetingInfo?.MediaRegion
    }
    }, 
    {
      "Attendee": {
        "ExternalUserId": stateMeeting?.attendeeInfo?.ExternalUserId,
        "AttendeeId": stateMeeting?.attendeeInfo?.AttendeeId,
        "JoinToken": stateMeeting?.attendeeInfo?.JoinToken
    }
    }
  );

  const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController as any);

  const meetingReadinessChecker = new DefaultMeetingReadinessChecker(logger, meetingSession);

  const checkAudioOutput = async () => {
    // safariでlistAudioOutputDevicesを取れない不具合があるため音声チェックは行わない
    // const audiOutputDeviceInfo = await meetingSession.audioVideo.listAudioOutputDevices();
    // new TestSound(audiOutputDeviceInfo[0].deviceId);
    checkDevices();
  };

  const checkDevices = async () => {
    // マイク
    const audioInputDeviceInfo = await meetingSession.audioVideo.listAudioInputDevices();
    const videoInputDeviceInfo = await meetingSession.audioVideo.listVideoInputDevices();
    // get deviceId from browser's api
    // let stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
    // let { width, height, deviceId } = stream.getVideoTracks()[0].getSettings();
    // get deviceId from library
    let stream = await navigator.mediaDevices.getUserMedia({ video: true });
    let { width, height } = stream.getVideoTracks()[0].getSettings();

    setCheckMic(TypeStatusDevices.LOADING);
    const audioInputFeedback = await meetingReadinessChecker.checkAudioInput(audioInputDeviceInfo[0].deviceId);
    if (audioInputFeedback === CheckAudioInputFeedback.Succeeded) {
      setCheckMic(TypeStatusDevices.OK);
    } else if (CheckAudioInputFeedback.PermissionDenied || CheckAudioInputFeedback.Failed) {
      setCheckMic(TypeStatusDevices.NG);
    }

    // カメラ
    setCheckCamera(TypeStatusDevices.LOADING);
    const videoInputFeedback = await meetingReadinessChecker.checkVideoInput(videoInputDeviceInfo[0].deviceId);
    if (videoInputFeedback === CheckVideoInputFeedback.Succeeded) {
      setCheckCamera(TypeStatusDevices.OK);
    } else if (CheckVideoInputFeedback.PermissionDenied || CheckVideoInputFeedback.Failed) {
      setCheckCamera(TypeStatusDevices.NG);
    }

    // カメラ解像度
    if (getOs() === OS.ANDROID) {
      // for android only
      const cameraResolutionResp1 = await meetingReadinessChecker.checkCameraResolution(
        videoInputDeviceInfo[0].deviceId,
        640,
        480,
      );
      const cameraResolutionResp2 = await meetingReadinessChecker.checkCameraResolution(
        videoInputDeviceInfo[0].deviceId,
        1280,
        720,
      );
      const cameraResolutionResp3 = await meetingReadinessChecker.checkCameraResolution(
        videoInputDeviceInfo[0].deviceId,
        1920,
        1080,
      );
      if (
        cameraResolutionResp1 === CheckCameraResolutionFeedback.Succeeded ||
        cameraResolutionResp2 === CheckCameraResolutionFeedback.Succeeded ||
        cameraResolutionResp3 === CheckCameraResolutionFeedback.Succeeded
      ) {
        setCheckResolution(TypeStatusDevices.OK);
      } else {
        setCheckResolution(TypeStatusDevices.NG);
      }
    } else {
      setCheckResolution(TypeStatusDevices.LOADING);
      // deviceId from browser's api
      // const cameraInputFeedback = await meetingReadinessChecker.checkCameraResolution(
      //   deviceId || '',
      //   width || 0,
      //   height || 0,
      // );
      const cameraInputFeedback = await meetingReadinessChecker.checkCameraResolution(
        videoInputDeviceInfo[0].deviceId,
        width || 0,
        height || 0,
      );

      if (cameraInputFeedback === CheckCameraResolutionFeedback.Succeeded) {
        setCheckResolution(TypeStatusDevices.OK);
      } else {
        setCheckResolution(TypeStatusDevices.NG);
      }
    }
    // 通信環境 ①TCP
    setCheckNetworkTCP(TypeStatusDevices.LOADING);
    const networkTCP = await meetingReadinessChecker.checkNetworkTCPConnectivity();
    if (networkTCP === CheckNetworkTCPConnectivityFeedback.Succeeded) {
      setCheckNetworkTCP(TypeStatusDevices.OK);
    } else if (CheckNetworkTCPConnectivityFeedback.ConnectionFailed) {
      setCheckNetworkTCP(TypeStatusDevices.NG);
    }
    // 通信環境②UDP
    setCheckNetworkUDP(TypeStatusDevices.LOADING);
    const networkUDP = await meetingReadinessChecker.checkNetworkUDPConnectivity();

    if (networkUDP === CheckNetworkUDPConnectivityFeedback.Succeeded) {
      setCheckNetworkUDP(TypeStatusDevices.OK);
    } else if (CheckNetworkUDPConnectivityFeedback.ConnectionFailed) {
      setCheckNetworkUDP(TypeStatusDevices.NG);
    }
    // 音声接続
    setCheckAudioConnect(TypeStatusDevices.LOADING);
    const audioConnectFeedback = await meetingReadinessChecker.checkAudioConnectivity(audioInputDeviceInfo[0].deviceId);
    if (audioConnectFeedback === CheckAudioConnectivityFeedback.Succeeded) {
      setCheckAudioConnect(TypeStatusDevices.OK);
    } else if (CheckAudioConnectivityFeedback.ConnectionFailed) {
      setCheckAudioConnect(TypeStatusDevices.NG);
    }

    // // 映像接続
    // setCheckVideoConnect(TypeStatusDevices.LOADING);
    // const videoConnectFeedback = await meetingReadinessChecker.checkVideoConnectivity(videoInputDeviceInfo[0].deviceId);
    // if (videoConnectFeedback === CheckVideoConnectivityFeedback.Succeeded) {
    //   setCheckVideoConnect(TypeStatusDevices.OK);
    // } else if (CheckVideoConnectivityFeedback.ConnectionFailed) {
    //   setCheckVideoConnect(TypeStatusDevices.NG);
    // }
  };
  return {
    checkAudioOutput,
    checkMic,
    checkCamera,
    checkResolution,
    checkNetworkUDP,
    checkNetworkTCP,
    checkAudioConnect,
    // checkVideoConnect,
  };
}
