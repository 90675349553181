import { useAudioInputs } from 'amazon-chime-sdk-component-library-react';
import React, { Dispatch, SetStateAction } from 'react';
import ModalLeaveMeeting from './ModalLeaveMeeting';
interface IProps {
  toggleMute: () => void;
  toggleVideo: () => void;
  onViewProfile: () => void;
  isVideoEnabled: boolean;
  isOpen: boolean;
  muted: boolean;
  setIsShowEndMeeting: Dispatch<SetStateAction<boolean>>;
}
function ControlMeeting(props: IProps) {
  const { toggleMute, muted, isVideoEnabled, toggleVideo, onViewProfile, isOpen, setIsShowEndMeeting } = props;
  const [show, setShow] = React.useState<boolean>(false);
  const { selectedDevice } = useAudioInputs();

  const handleClose = () => {
    setShow(false);
  };

  const onLeave = () => {
    setShow(true);
  };

  const onSuccess = () => {
    setIsShowEndMeeting(true);
    setShow(false);
  };
  return (
    <div className="control-container">
      <div className="border-view" onClick={toggleMute}>
        <img
          src={selectedDevice && !muted ? '/assets/images/mic_meeting.png' : '/assets/images/mic_mute.png'}
          alt="mic meeting"
          width={30}
        />
        <p>{selectedDevice && !muted ? 'マイクオン' : 'マイクオフ'}</p>
      </div>
      <div className="border-view" onClick={toggleVideo}>
        <img
          src={isVideoEnabled ? '/assets/images/video_camera.png' : '/assets/images/no_video.png'}
          alt="mic meeting"
          width={30}
        />
        <p>{isVideoEnabled ? 'ビデオオン' : 'ビデオオフ'}</p>
      </div>
      <div className="border-view" onClick={onViewProfile}>
        <img
          src={isOpen ? '/assets/images/document.png' : '/assets/images/no_document.png'}
          alt="mic meeting"
          width={30}
        />
        <p>{isOpen ? 'プロフィールオン' : 'プロフィールオフ'}</p>
      </div>
      <div className="border-view" onClick={onLeave}>
        <img src="/assets/images/end_call.png" alt="mic meeting" width={30} />
        <p>終了</p>
      </div>
      <ModalLeaveMeeting show={show} handleClose={handleClose} onSuccess={onSuccess} />
    </div>
  );
}

export default ControlMeeting;
