import React from 'react';
import { meetingState } from '@/states/request-meeting';
import { useRecoilState } from 'recoil';
import { RequestIdInfo, Time } from '@helpers/types';
import { getTimeStart, objectTimeAddHalfHour, diffSecondsFromNow, convertSecondsToMinutesSeconds } from '@helpers/time';
import { isEmptyObject } from '@helpers/object';
import { useRosterState, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { useGetStatusAttendee } from '../../MeetingConfirm/hooks/useGetStatusAttendee/useGetStatusAttendee';
import { useSearchParams } from 'react-router-dom';

export function useCountUpNumber() {
  const [searchParams] = useSearchParams();
  const request_id = searchParams.get('request_id');
  const meetingManager = useMeetingManager();
  const [state_meeting] = useRecoilState(meetingState);
  const { getVideoStatusAttendee } = useGetStatusAttendee();
  const [timeLeft, setTimeLeft] = React.useState<{ minutes?: number; seconds?: number }>({});
  const [timeEndObject, setTimeEndObject] = React.useState<Time | {}>({});
  // const endCount: number = 10;
  const TIME_START_NOTIFY = 10;
  const TIME_END_NOTIFY = 0;
  const [isOpenNotify, setOpenNotify] = React.useState<boolean>(false);
  const [isNotifyUserLeft, setIsNotifyUserLeft] = React.useState<boolean>(false);

  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const [readyOmiai, setReadyOmiai] = React.useState<boolean>(false);
  const partnerTileId = React.useMemo(() => {
    // お相手のTitleId
    let data;
    for (var i = 0; i < attendees?.length; i++) {
      if (attendees[i]?.chimeAttendeeId !== state_meeting?.attendeeInfo.AttendeeId) {
        if (
          attendees[i]?.externalUserId === state_meeting?.zwei_meeting_info?.member_id ||
          attendees[i]?.externalUserId === state_meeting?.zwei_meeting_info?.partner_id
        ) {
          data = getVideoStatusAttendee(attendees[i]?.chimeAttendeeId);
          break;
        }
      }
    }
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendees]);

  // React.useEffect(() => {
  //   console.log('Roster changed', roster);
  // }, [roster]);

  React.useEffect(() => {
    // console.log('attendees changed', attendees);
    if (checkStartOmiai()) {
      setReadyOmiai(true);
    }
    checkOneUserLeft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendees]);

  React.useEffect(() => {
    if (readyOmiai && request_id) {
      // console.log("readyOmiai changed and it's true");
      //お見合い開始時にmuteを解除
      meetingManager?.meetingSession?.audioVideo.realtimeUnmuteLocalAudio();
      const requestIdInfo: RequestIdInfo = getTimeStart(request_id);
      setTimeEndObject(objectTimeAddHalfHour(requestIdInfo.timeStart));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyOmiai, request_id]);

  React.useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (!isEmptyObject(timeEndObject))
      interval = setInterval(() => {
        // if (minute < TIME_MAX) {
        //   setSecond((prevSecond) => prevSecond++);
        // }
        const { minutes, seconds } = convertSecondsToMinutesSeconds(diffSecondsFromNow(timeEndObject as Time));
        setTimeLeft({ minutes, seconds });
      }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timeEndObject]);

  // React.useEffect(() => {
  //   if (second === 60) {
  //     setMinute((prevMinute) => prevMinute++);
  //     setSecond(0);
  //   }
  // }, [second]);

  React.useEffect(() => {
    if (typeof timeLeft.minutes !== 'undefined' && typeof timeLeft.seconds !== 'undefined') {
      if (timeLeft.minutes <= TIME_START_NOTIFY) {
        setOpenNotify(true);
      }
      if (
        (timeLeft.minutes === TIME_END_NOTIFY && timeLeft.seconds === TIME_END_NOTIFY) ||
        timeLeft.minutes < TIME_END_NOTIFY ||
        timeLeft.seconds < TIME_END_NOTIFY
      ) {
        setOpenNotify(false);
      }
    }
  }, [timeLeft]);

  const checkStartOmiai = () => {
    if (!state_meeting?.zwei_meeting_info?.matchmaking_at) {
      // console.log('checkStartOmiai return false because data from api not existed');
      return false;
    }
    //atendeeのビデオが準備できているか
    let remote_attendee_ids = meetingManager?.audioVideo
      ?.getRemoteVideoSources()
      .map((i) => i?.attendee?.externalUserId);
    let attendee_ids = remote_attendee_ids?.concat(state_meeting?.zwei_meeting_info?.my_id);
    if (
      !(
        attendee_ids?.includes(state_meeting?.zwei_meeting_info?.member_id) &&
        attendee_ids?.includes(state_meeting?.zwei_meeting_info?.partner_id)
      )
    ) {
      return false;
    }
    let now;
    let start_date = new Date(state_meeting?.zwei_meeting_info?.matchmaking_at);
    do {
      now = new Date();
    } while (now <= start_date);
    return true;
  };

  const checkOneUserLeft = () => {
    const externalUserIds = attendees?.map((attendee) => attendee.externalUserId);
    // console.log('externalUserIds:', externalUserIds);
    if (
      !(
        externalUserIds?.includes(state_meeting?.zwei_meeting_info?.member_id) &&
        externalUserIds?.includes(state_meeting?.zwei_meeting_info?.partner_id)
      )
    ) {
      setIsNotifyUserLeft(true);
    } else {
      setIsNotifyUserLeft(false);
    }
  };

  return {
    // endCount,
    readyOmiai,
    timeLeft,
    isOpenNotify,
    isNotifyUserLeft,
    TIME_END_NOTIFY,
    partnerTileId,
  };
}
