/** @format */

import { IUser, TypeDataRequestMeeting } from '@helpers/types';
import { atom } from 'recoil';
export interface GlobalState {
  language: string;
  data_meeting?: TypeDataRequestMeeting;
  userInfo?: IUser;
}
const GLOBAL_STATE = 'GLOBAL_STATE';
export const globalState = atom<GlobalState>({
  key: GLOBAL_STATE,
  default: {
    language: 'en',
    data_meeting: undefined,
    userInfo: undefined,
  },
});
