/** @format */

import React from 'react';
import '../styles/no-remote-video.scss';
interface IProps {
  text: string;
}
function NoRemoteVideoView(props: IProps) {
  const { text } = props;
  return (
    <div id="noRemoteVideoView">
      <div className="view-container">{text}</div>
    </div>
  );
}

export default NoRemoteVideoView;
