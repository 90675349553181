import { Modal } from 'react-bootstrap';
interface IProps {
  show: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}
function ModalLeaveMeeting(props: IProps) {
  const { show, handleClose, onSuccess } = props;
  return (
    <Modal show={show} onHide={handleClose} className='modal--leave-meeting'>
      <Modal.Body>お見合いを終了しますよろしいですか？</Modal.Body>
      <div className="container-modal-bottom">
        <button className="btn-cancel" onClick={handleClose}>
          キャンセル
        </button>
        <button className="btn-leave" onClick={onSuccess}>
          ミーティングを退出
        </button>
      </div>
    </Modal>
  );
}

export default ModalLeaveMeeting;
