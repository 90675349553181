/** @format */

import { IUser } from '@helpers/types';
import { selector } from 'recoil';
import { globalState } from './globalState';

const USER_INFO = 'USER_INFO';
export const userInfoState = selector<IUser | undefined>({
  key: USER_INFO,
  get: ({ get }) => {
    const stateGlobal = get(globalState);
    return stateGlobal.userInfo;
  },
  set: ({ get, set }, newValue) => {
    const state = get(globalState);
    const newDataMeeting = newValue as IUser;
    set(globalState, { ...state, userInfo: newDataMeeting });
  },
});
