/** @format */

import { QueryClient, setLogger } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      enabled: true,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
    mutations: {
      onError: (error: any) => {},
      onSuccess: (data: any, variables: any) => {},
    },
  },
});

setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});

export default queryClient;
