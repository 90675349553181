import React from 'react';
import '@modules/Meetings/styles/notify-second.scss';
// import { useMeeting } from '../../MeetingConfirm/hooks/useJoinMeeting/useMeeting';
interface IProps {
  minute: number | undefined;
  second: number | undefined;
  timeMax?: number;
}
function NotifySecond(props: IProps) {
  // const { minute, second, timeMax } = props;
  // const minute_remaining = timeMax - minute;
  // const second_remaining = 60 - second;
  // const { leaveMeeting } = useMeeting();
  const { minute, second } = props;
  // React.useEffect(() => {
  //   if (minute_remaining < 1 && second_remaining === 0) {
  //     leaveMeeting();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [minute_remaining]);
  return (
    <div id="notifySecond">
      <div className="notify-container">
        {minute !== 0 ? `残り時間終了まであと ${minute} 分です。` : `残り時間終了まであと ${second} 秒です。`}
      </div>
    </div>
  );
}

export default NotifySecond;
