import React from 'react';
import HeaderLayout from './header';
import I18n from '@translate/i18n';
import { useRecoilValue } from 'recoil';
import { languageState } from '@/states/language';
import { setTitle } from '@helpers/dom';

interface IProps {
  children: React.ReactNode;
}
function EmptyLayout(props: IProps) {
  const [locale, setLocale] = React.useState<string>('');

  React.useEffect(() => {
    setTitle('ZWEI : ミーティング');
  }, []);

  const language = useRecoilValue(languageState);
  React.useEffect(() => {
    if (language) {
      setLocale(language);
    }
  }, [language]);

  React.useEffect(() => {
    if (locale) {
      I18n.changeLanguage(locale);
    }
  }, [locale]);

  return (
    <div id="emptyLayout">
      <HeaderLayout />
      {props.children}
    </div>
  );
}

export default EmptyLayout;
