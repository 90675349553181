import { Modal, Button } from 'react-bootstrap';
interface IProps {
  show: boolean;
  handleClose: () => void;
}
function ModalEndMeeting(props: IProps) {
  const { show, handleClose } = props;
  return (
    <Modal show={show} onHide={handleClose} className="modal--end-meeting">
      <Modal.Body>
        本日のお見合いは終了しました。
        <br /> 元の画面に戻る
      </Modal.Body>
      <div className="container-modal-bottom">
        <Button variant="primary" onClick={handleClose}>
          元の画面に戻る
        </Button>
      </div>
    </Modal>
  );
}

export default ModalEndMeeting;
