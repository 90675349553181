/** @format */

import { getCaller } from '@services/api-caller';

export const apiGetMeeting = async (id: string, token: string) => {
  if (id) {
    const res = await getCaller(`/requests/${id}/meetings?token=${token}`);
    return res.data;
  }
};
