/** @format */

import env from '@/env';
import axios from 'axios';

axios.defaults.baseURL = env.apiEndPoint;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  function (config) {
    config.headers = {
      accept: 'application/json',
      'Content-Type' : 'application/json'
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

const auth = {
  username: env.username,
  password: env.password,
};

export const postCaller = (url: string, includeAuth = true, data: any) => {
  return axios
    .post(url, data, {
      auth
    })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const getCaller = (url: string, includeAuth = true) => {
  return axios
    .get(url, {
      auth
    })
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

