import React, { useRef, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import '@modules/Meetings/styles/slide-burger-menu.scss';
import { useRecoilState } from 'recoil';
import { userInfoState } from '@/states/user-info';
interface IProps {
  isOpen: boolean;
  right: boolean;
  profileRandom: number;
  iframeSrc?: string;
}
function SlideBurgerMenu(props: IProps) {
  const [userInfo] = useRecoilState(userInfoState);
  const [width, setWidth] = React.useState<string>('320px');
  const [height, setHeight] = React.useState<string>('150px');

  const { isOpen, right, profileRandom, iframeSrc } = props;
  const elm = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (elm===null|| elm.current===null) return;
    const size=elm.current.getBoundingClientRect()
    setWidth(size['width']+'px');
    setHeight(size['height']+'px');
  }, []);
// {"x":410,"y":541.8125,"width":370,"height":154,"top":541.8125,"right":780,"bottom":695.8125,"left":410}
  return (
    <div id="slideBurgerMenu" >
      <Menu width={400} isOpen={isOpen} right={right} pageWrapId={ "side-menue-wrap" }>
        <div ref={elm}>
          { userInfo && (
            <iframe key={profileRandom} id="profile_ifame" width={width} height={height} src={iframeSrc}></iframe>
          )}
        </div>
      </Menu>
    </div>
  );
}

export default SlideBurgerMenu;
